import { useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
//firebase
import FirebaseApp from 'firebase/app';
import './App.scss'
import "firebase/auth";
import 'react-responsive-modal/styles.css';

import firebase from "firebase/app";
import {firebaseConfig} from "./config"
//Apollo Client

import { SocketProvider } from 'src/network/SocketContext';

import { useTheme } from "@material-ui/core/styles"

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { getSessionToken } from './utils/sessionManager';


import {Toaster} from "react-hot-toast";

// components
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import '../src/css/app.css';
import { sheruLog } from './utils/LogHelper';

// ----------------------------------------------------------------------

const graphqlUriDev = `${process.env.REACT_APP_BACKEND_URL}/graphql`  //REACT_APP_GRAPHQL_URL
const graphqlUriProd = `${process.env.REACT_APP_BACKEND_URL}/graphql` //REACT_APP_GRAPHQL_URL

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENVIRONMENT === "development" ? graphqlUriDev: graphqlUriProd
});

const authLink = setContext((_, { headers }) => {
  const token = process.env.REACT_APP_JWT_TOKEN
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link:authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export default function App(props) {
  
  const theme = useTheme();

  useEffect(()=>{
    sheruLog("App", "useEffect");
  // toast.configure();
    getSessionToken();
    // toast.configure();
  },[])

  if (!firebase.apps.length) {
    sheruLog("App", "Firebade initializing");
    FirebaseApp.initializeApp(firebaseConfig);
  }else {
    sheruLog("App", "firebase already initialized");
    FirebaseApp.app(); // if already initialized, use that one
  }
    
  return (
    <ApolloProvider client={client}>
      <SocketProvider>
        <ThemeConfig>
          <ThemePrimaryColor>
            {/* <RtlLayout> */} 
              <NotistackProvider>
                <div><Toaster/></div>
                {/* <Settings /> */}
                <ScrollToTop />
                <GoogleAnalytics />
              <Router />
              </NotistackProvider>
            {/* </RtlLayout> */}
          </ThemePrimaryColor>
        </ThemeConfig>
      </SocketProvider>
    </ApolloProvider>
  );

}