// material
import React from "react"
import Grid from "@material-ui/core/Grid";
import { useTheme } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import ApiConstants from "src/utils/ApiConstants";

//styles
// import "./css/footer.css";
// import "./css/footer_media.css";
import styles from "./css/page_footer.module.css";

export default function PageFooter() {

  const theme = useTheme();

  const AboutUsSection = () => {
    return(
      <div style={{ display:'flex', flexDirection:'column', alignItems:'start', gap:'15px'}}>
        
        <a className="clickable" href={ApiConstants.REACT_APP_FRONTEND_URL} target="_blank" style={{textDecoration:'none'}}>
          <div style={{minHeight:'25px', minWidth:'75px'}}>
            <img src="/icon_new/sheru_black.svg" style={{maxHeight:'25px', marginTop:'10px'}}/>
          </div>
        </a>

        <div style={{display:'flex', flexDirection:'column', gap:'5px'}}>
          <p style={{ fontSize:'110%', color:`${theme.palette.text.primary}`, fontFamily:"outfitSemiBold", lineHeight:'1.2'  }}>About us</p>
          <p style={{ fontSize:'100%', color:`${theme.palette.text.secondary}`, fontFamily:"outfitLight", fontWeight:'lighter', lineHeight:'1.2' }}>
            <span>We believe that energy can be sustainable, scalable, profitable &amp; managed responsibly. We provide tools to support all of the above.</span>
          </p>
        </div>

        <a className="clickable" href="https://unreasonablegroup.com/ventures/sheru" target="_blank" style={{textDecoration:"none"}}>
          <img src="/logo/sheru/unreasonable-seal-black.png" style={{maxHeight:'55px', minHeight:"55px"}}/>
        </a>
      </div>
    )
  }

  const OtherPageLinks = () => {
    return(
      <div className={styles.pageLinksOuterContainer}>
        <div className={styles.pageLinksInnerContainer} data-wow-delay="0.2s">

          <p className={styles.footerSectionHeader} style={{color:`${theme.palette.text.primary}`}}>Quick links</p>
          
          <div className={styles.pageLinksItemsContainer}>
            
            <p className="clickable" style={{ color: `${theme.palette.text.secondary}`, fontWeight:'lighter' }}>
              <a href={ApiConstants.REACT_APP_FRONTEND_URL + "our_code" + ApiConstants.REACT_APP_POSTEND_URL} target="_blank" style={{ fontFamily:"outfitLight", fontWeight:'lighter', textDecoration: "none", fontSize: '100%', color: `${theme.palette.text.secondary}` }}> Our Code </a>
            </p>

            <p className="clickable" style={{ color: `${theme.palette.text.secondary}`, fontWeight:'lighter' }}>
              <a href={ApiConstants.REACT_APP_FRONTEND_URL + "terms_conditions" + ApiConstants.REACT_APP_POSTEND_URL} target="_blank" style={{ fontFamily:"outfitLight", fontWeight:'lighter', textDecoration: "none", fontSize: '100%', color: `${theme.palette.text.secondary}` }}> Terms &amp; conditions</a>
            </p>

            <p className="clickable" style={{ color: `${theme.palette.text.secondary}`, fontWeight:'lighter' }}>
              <a href={ApiConstants.REACT_APP_FRONTEND_URL + "privacy_policy" + ApiConstants.REACT_APP_POSTEND_URL} target="_blank" style={{ fontFamily:"outfitLight", fontWeight:'lighter', textDecoration: "none", fontSize: '100%', color: `${theme.palette.text.secondary}` }}>Privacy policy</a>
            </p>

            <p className="clickable" style={{ color: `${theme.palette.text.secondary}`, fontWeight:'lighter' }}>
              <a href={ApiConstants.REACT_APP_FRONTEND_URL + "eula" + ApiConstants.REACT_APP_POSTEND_URL} target="_blank" rel="noopener noreferrer" style={{ fontFamily:"outfitLight", fontWeight:'lighter', textDecoration: "none", fontSize: '100%', color: `${theme.palette.text.secondary}` }}>EULA</a>
            </p>


            <p className="clickable" style={{ color: `${theme.palette.text.secondary}` }}>
              <a href={"mailto:support@sheru.se"} style={{ fontFamily:"outfitLight", fontWeight:'lighter', textDecoration: "none", fontSize: '100%', color: `${theme.palette.text.secondary}` }}>support@sheru.se</a>
            </p>

            <p className="clickable" style={{ color: `${theme.palette.text.secondary}` }}>
              <a href={"tel:01141133961"} rel="noopener noreferrer" style={{ fontFamily:"outfitLight", fontWeight:'lighter', textDecoration: "none", fontSize: '100%', color: `${theme.palette.text.secondary}` }}>011 41133961</a>
            </p>

          </div>
        </div>
      </div>
    )
  }

  const SocialMediaSection = () => {
    return(
      <div className={styles.pageSocialOuterContainer}>
        <div className={styles.pageLinksInnerContainer} data-wow-delay="0.2s">

          <p className={styles.footerSectionHeader} style={{color:`${theme.palette.text.primary}`}}>Follow us</p>
          
          <div className={styles.socialLinksItemsContainer}>

            <a className="clickable" href="https://www.linkedin.com/company/sheru/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
                <img alt="linkedin" loading="lazy" src="/static/icon/social/linkedin.png" style={{ maxHeight:'25px' }}/>
                <p style={{ fontSize:'100%', color:`${theme.palette.text.secondary}`, fontFamily:"outfitLight", fontWeight:'lighter', lineHeight:'1.2' }}>LinkedIn</p>
              </div>
            </a>

            <a className="clickable" href="https://open.spotify.com/show/1dFLKFtTF7Umo3eakpxYM4" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
                <img alt="spotify" loading="lazy" src="/static/icon/social/spotify.png" style={{ maxHeight:'25px' }}/>
                <p style={{ fontSize:'100%', color:`${theme.palette.text.secondary}`, fontFamily:"outfitLight", fontWeight:'lighter', lineHeight:'1.2' }}>Spotify</p>
              </div>
            </a>

            <a className="clickable" href="https://twitter.com/sheruRoar" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
                <img alt="twitter" loading="lazy" src="/static/icon/social/twitter_x.png" style={{ maxHeight:'25px' }}/>
                <p style={{ fontSize:'100%', color:`${theme.palette.text.secondary}`, fontFamily:"outfitLight", fontWeight:'lighter', lineHeight:'1.2' }}>X.com</p>
              </div>
            </a>

            <a className="clickable" href="https://www.youtube.com/channel/UCh3Wbl8jd1FMwQzwHwDTYlQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
                <img alt="youtube" loading="lazy" src="/static/icon/social/youtube.png" style={{ maxHeight:'25px' }}/>
                <p style={{ fontSize:'100%', color:`${theme.palette.text.secondary}`, fontFamily:"outfitLight", fontWeight:'lighter', lineHeight:'1.2' }}>YouTube</p>
              </div>
            </a>

          </div>

        </div>
      </div>
    )
  }

  return (
    <div style={{width:'100%', display:'flex', flexDirection:'column', paddingTop:'20px', background:`${theme.palette.background.page}`}}>
      <Grid container>

        {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}/> */}
   
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div style={{ width: '100%', maxWidth: '100%'}}>
            <Container maxWidth={"xl"}>
              <div style={{ width: '100%', height: '100%', paddingTop:"10px", paddingBottom:'5px'}}>
                
                <Grid container>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding:'5px 10px' }}>
                    <AboutUsSection/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding:'5px 10px' }}>
                    <OtherPageLinks />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding:'5px 10px' }}>
                    <SocialMediaSection/>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px' }}>
                    <p className={styles.copyrightText} style={{color:`${theme.palette.text.secondary}`}}> Copyright © 2019 Sheru, All Rights Reserved</p>
                  </Grid>
                </Grid>
                
              </div>
            </Container>
          </div>
        </Grid>

        {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}/> */}

      </Grid>

    </div>
  );
}

