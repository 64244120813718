import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios';
import { config } from 'src/network/tokenHelper';

// const config = {
//     headers: {
//         Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
//     }
// }

export const Context = createContext()

const UserContext = ({ children }) => {

    const [userObject, setUserObject] = useState(
      
    )

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/auth/userByUid`, { uid: localStorage.getItem("userId") }, config).then((res) => {
            if (res?.data) {
                // console.log("userByUid : " + JSON.stringify(res?.data))
                localStorage.setItem("phone", res?.data?.phoneNumber);
                setUserObject(res?.data?.user)
            }
        })
    },[localStorage.getItem("userId")])
    return(
        <Context.Provider value={{userOb:userObject}}>{children}</Context.Provider>
    )
}

export default UserContext