import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "src/layouts/dashboard";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
// guards
import AuthGuard from "src/guards/AuthGuard";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";

// components
import LoadingScreen from "src/components/LoadingScreen";
import { Context } from "src/contexts/UserContext";

// ----------------------------------------------------------------------
import SecureLS from "secure-ls";
// import { config } from 'src/network/tokenHelper';
// const config = {
//   headers: {
//     Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
//   },
// };

import React, { useContext } from "react";
var ls = new SecureLS();



const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // console.log(pathname,"pn");
  let isDashboard = false
  if (pathname) {
    isDashboard = pathname.includes("/dashboard");

  }

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

//tata power

const NotificationsPage = Loadable(lazy(() => import('src/pages/dashboard/notification/NotificationsPage')))


const LiveDataTrack = Loadable(lazy(() => import('src/pages/dashboard/liveData/LiveDataTrack')))

const TataPower = Loadable(lazy(() => import('src/pages/dashboard/tata-power/TataPower')))
const TataPowerCoreDetails = Loadable(lazy(() => import('src/pages/dashboard/tata-power-core/TataPowerCoreDetail')))
const TataPowerCoreCamera = Loadable(lazy(() => import('src/pages/dashboard/tata-power-core/TataPowerCoreCamera')))
const TataPowerNodeDetails = Loadable(lazy(() => import('src/pages/dashboard/tata-power-node/TataPowerNodeDetail')))

const TataPowerCoreAnalysis = Loadable(lazy(() => import('src/pages/dashboard/tata-power-core/TataPowerCoreAnalysis')))
const TataPowerNodeAnalysis = Loadable(lazy(() => import('src/pages/dashboard/tata-power-node/TataPowerNodeAnalysis')))

const TataPowerNodeExport = Loadable(lazy(() => import('src/pages/dashboard/tata-power-node/TataPowerNodeExport')))
const TataPowerCoreExport = Loadable(lazy(() => import('src/pages/dashboard/tata-power-core/TataPowerCoreExport')))

const TataPowerCoreTempAnalysis = Loadable(lazy(() => import('src/pages/dashboard/tata-power-core/analysis/TataPowerCoreTempAnalysis')))
// Authentication
const Login = Loadable(lazy(() => import("src/pages/authentication/Login")));

// Dashboard
// const HomePage = Loadable( lazy(() => import("src/pages/dashboard/home-page/HomePage")));

const Page500 = Loadable(lazy(() => import("src/pages/Page500")));
const NotFound = Loadable(lazy(() => import("src/pages/Page404")));

export default function Router() {

  const theme = useTheme();

  const { userOb: userObject } = useContext(Context);

  const LoadingView = () => {
    return (
      <Container maxWidth={"xl"}>
        <div
          style={{
            width: "100%",
            minHeight: "70vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: `${theme.palette.background.page}`,
            padding: "10px",
            borderRadius: "25px",
          }}
        >
          <CircularProgress size={25}/>
          {/* <p>Getting profile</p> */}
        </div>
      </Container>
    );
  };

  const GuardFunction = ({ children }) => {
    if (userObject) {
      // console.log(userObject);
      if (userObject.emailVerified) {
        // console.log(userObject);
        return <>{children}</>;
      } else if (!userObject.emailVerified) {
        // console.log(userObject);
        return <Navigate to="/500" />;
      }
    } else {
      // console.log(4);
      return <LoadingView />;
    }
  };

  const RoleGuard = ({ children, url }) => {
    return children
  };

  return useRoutes([
    {
      path: "auth",
      children: [
        { path: "login", element: <Login /> },
        { path: "login-unprotected", element: <Login /> },

      ],
    },
    {
      path: "dashboard",
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: (<GuardFunction><RoleGuard url="tata-power"><TataPower url="tata-power" /></RoleGuard></GuardFunction>) },

        { path: "notifications", element: (<GuardFunction><RoleGuard url="tata-power"><NotificationsPage url="tata-power" /></RoleGuard></GuardFunction>) },

        //tata power
        { path: "tata-power", element: (<GuardFunction><RoleGuard url="tata-power"><TataPower url="tata-power" /></RoleGuard></GuardFunction>) },

        { path: "tata-core-detail", element: (<GuardFunction><RoleGuard url="tata-core-detail"><TataPowerCoreDetails url="tata-core-detail" /></RoleGuard></GuardFunction>) },
        { path: "tata-core-camera", element: (<GuardFunction><RoleGuard url="tata-core-detail"><TataPowerCoreCamera url="tata-core-camera" /></RoleGuard></GuardFunction>) },
        { path: "tata-node-detail", element: (<GuardFunction><RoleGuard url="tata-node-detail"><TataPowerNodeDetails url="tata-node-detail" /></RoleGuard></GuardFunction>) },

        { path: "tata-node-analysis", element: (<GuardFunction><RoleGuard url="tata-node-analysis"><TataPowerNodeAnalysis url="tata-node-analysis" /></RoleGuard></GuardFunction>) },
        { path: "tata-core-analysis", element: (<GuardFunction><RoleGuard url="tata-node-analysis"><TataPowerCoreAnalysis url="tata-node-analysis" /></RoleGuard></GuardFunction>) },
        // { path: "tata-DER", element: (<GuardFunction><RoleGuard url="tata-DER"><RoleGuard url="tata-DER"><NavBatteries url="tata-DER" /></RoleGuard></RoleGuard></GuardFunction>) },
        { path: "tata-node-export", element: (<GuardFunction><RoleGuard url="tata-node-export"><TataPowerNodeExport url="tata-node-export" /></RoleGuard></GuardFunction>) },
        { path: "tata-core-export", element: (<GuardFunction><RoleGuard url="tata-core-export"><TataPowerCoreExport url="tata-core-export" /></RoleGuard></GuardFunction>) },

        { path: "tata-core-temp-analysis", element: (<GuardFunction><RoleGuard url="tata-core-export"><TataPowerCoreTempAnalysis url="tata-core-export" /></RoleGuard></GuardFunction>) },
        
        //live tracking
        { path: "live-data", element: (<GuardFunction><RoleGuard url="tata-power"><LiveDataTrack url="tata-power" /></RoleGuard></GuardFunction>) },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [

        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      children: [
        { path: "/", element: <Navigate to="/dashboard" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
