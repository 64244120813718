// src/network/socket.js
import { io } from 'socket.io-client';

const SOCKET_SERVER_URL = "https://redislistener.sheru.se/"; // Replace with your server URL

const socket = io(SOCKET_SERVER_URL, {
    autoConnect: false,
});

// Optional: Handle global socket events here
socket.on('connect', () => {
    console.log('Socket connected');
});

socket.on('disconnect', () => {
    console.log('Socket disconnected');
});

socket.on('connect_error', (err) => {
    console.error('Socket connection error:', err.message);
});

export default socket;