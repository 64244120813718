// src/context/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import socket from 'src/network/socket';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        socket.connect();

        socket.on('connect', () => setIsConnected(true));
        socket.on('disconnect', () => setIsConnected(false));
        socket.on('connect_error', (err) => setError(err.message));

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        console.log("socketError", JSON.stringify(error))
    }, [error]);

    const subscribe = (path, callback) => {
        if (path) {
            socket.emit('subscribe', path);
            socket.on('update', (updateData) => {
                if (updateData?.path === path) { // Assuming server sends the path with update
                    callback(updateData.data);
                }
            });
        }
    };

    const unsubscribe = (path) => {
        if (path) {
            socket.emit('leave', path);
            // Optionally remove listeners if necessary
        }
    };

    return (
        <SocketContext.Provider value={{ isConnected, error, subscribe, unsubscribe }}>
            {children}
        </SocketContext.Provider>
    );
};
